<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações básicas">
            <b-card-text>
              <div class="form-row">
                <b-col md="8">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <b-form-input
                      v-model="item.title"
                      :class="{ 'is-invalid': $v.item.title.$error }" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="mb-0">
                    <label for="">
                      #ID - MIGRAR
                      <feather-icon
                        size="16"
                        class="text-info"
                        icon="HelpCircleIcon"
                        v-b-tooltip.hover.v-dark
                        title="Caso esteja migrando o produto da versão anterior da Seducar, insira o ID do produto, localizado na tabela de Produtos Digitais > Curso online."
                      />
                    </label>
                    <b-form-input
                      type="number"
                      v-model="item.id_old" />
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="mb-1" header="Descrição">
            <b-card-text>
              <div class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <quill-editor
                      :class="{ 'is-invalid': $v.item.description.$error }"
                      v-model="item.description"
                      :options="snowOption"
                      :auto-height="true"
                    >
                    </quill-editor>
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Sobre">
            <b-card-text>
              <div class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <quill-editor
                      v-model="item.about"
                      :options="snowOptionSimple"
                      :auto-height="true"
                    >
                    </quill-editor>
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>
          
          <b-card header="Disciplinas" class="mb-1">
                <div class="form-row justify-content-between mb-2">
              <div class="col-md-8 col-8">
                <p class="mb-0">
                  Você pode organizar as disciplinas clicando no ícone
                  <feather-icon size="16" icon="MoveIcon" />
                </p>
              </div>
              <div class="col-md-4 text-right col-4">
                <b-button
                  @click="$bvModal.show('createDiscipline')"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-dark btn-sm btn-block"
                >
                  Selecionar disciplina
                </b-button>
              </div>
            </div>
            
            <draggable
              tag="ul"
              :list="item.disciplines"
              class="list-group"
              handle=".handle"
              :class="item.disciplines.length ? 'mt-2' : ''"
            >
              <li
                class="list-group-item"
                v-for="(element, idx) in item.disciplines"
                :key="idx"
              >
                <div class="form-row">
                  <div class="col-md-11">
                    <feather-icon icon="MoveIcon" class="handle" size="15" />
                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>
                  </div>
                  <div class="col-md-1">
                    <button
                      type="button"
                      style="padding: 0px!important"
                      class="btn btn-block btn-sm btn-default text-danger"
                      @click="removeAt(idx)">
                        <feather-icon
                          icon="XCircleIcon"
                          class="float-right"
                          size="20"
                        />
                    </button>
                  </div>
                </div>
              </li>
            </draggable>
          </b-card>

          <b-card class="mb-1" header="Sites de buscas">
            <b-card-text>
              <p class="mb-0">
                Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo.
              </p>
           
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título SEO</label>
                    <input v-model="item.title_seo" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">URL</label>
                    <b-input-group
                      :prepend="`/cursos/rapidos/`"
                      class="input-group-merge"
                    >
                      <b-form-input class="text-primary pl-1" v-model="item.slug" />
                    </b-input-group>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Descrição para SEO</label>
                    <textarea v-model="item.description_seo" maxlength="320" class="form-control"></textarea>
                  </div>
                </div>
              </div>
             </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  >
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="cursor: pointer;font-size: 1.1rem;"
                    >
                      <div
                        v-if="!preview"
                        class="pt-5 py-4"
                      >
                        <feather-icon
                          size="19"
                          icon="ImageIcon"
                        />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%;height: 200px;"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Vídeo de apresentação</label>
                    <b-form-input
                      type="text"
                      placeholder="Link do YouTube/Vimeo"
                      v-model="item.video_url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Label da imagem
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input
                      :class="{ 'is-invalid': $v.item.label_image.$error }"
                      v-model="item.label_image"
                      type="text" />
                  </b-form-group>
                  <small
                    class="d-block"
                    style="margin-top: 4px"
                  >
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Ficha Técnica">
            <b-card-text>
              <v-select
                ref="selectDatasheet"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.datasheet"
                placeholder="Digite o título"
                :options="optionsDatasheets"
                @search="fetchDatasheets"
                :class="{ 'is-invalid': $v.item.datasheet.$error }"
              >
                <span slot="no-options" @click="$refs.selectDatasheet.open = false">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Detalhes">
            <b-card-text>
              <div class="row">
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Tempo de acesso <small>(dias) </small>
                    </label>
                    <input
                      v-mask="['####']"
                      type="tel" min="1"
                      v-model="item.validity"
                      :class="{ 'is-invalid': $v.item.validity.$error }"
                      placeholder="365"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <div class="col-md-12 col-12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Subcategoria
                  </label>
                  <b-form-group>
                    <v-select
                      ref="selectSub"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.subcategory"
                      placeholder="Digite o título"
                      :options="optionsSubcategories"
                      @search="fetchSubcategories"
                      :class="{ 'is-invalid': $v.item.subcategory.$error }"
                    >
                      <span slot="no-options" @click="$refs.selectSub.open = false">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12 col-12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Área(s)
                  </label>
                  <b-form-group>
                    <v-select
                      multiple
                      ref="selectAreas"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.areas"
                      placeholder="Digite o título"
                      :options="optionsAreas"
                      @search="fetchAreas"
                      :class="{ 'is-invalid': $v.item.areas.$error }"
                    >
                      <span slot="no-options" @click="$refs.selectAreas.open = false">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12 col-12">
                  <div class="form-group mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Sales force
                    </label>
                    <v-select
                      ref="selectSalesForce"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.salesforce"
                      placeholder="Digite o título"
                      :options="optionsSalesForce"
                      @search="fetchSalesForce"
                      :class="{ 'is-invalid': $v.item.salesforce.$error }"
                    >
                      <span slot="no-options" @click="$refs.selectSalesForce.open = false">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="mb-1" header="Preço">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Preço
                    </label>
                    <money
                      class="form-control"
                      v-model="item.price"
                      placeholder="0,00"
                      v-bind="money"
                      :class="{ 'is-invalid': $v.item.price.$error }"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for="">
                      Promocional
                    </label>
                    <money
                      class="form-control"
                      v-model="item.sale"
                      placeholder="0,00"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <label for="">
                      Promoção válida até
                    </label>
                    <flat-pickr
                      v-model="item.promotion_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        altFormat: 'Y-m-d',
                        locale: 'pt',
                      }" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Opcionais">
            <b-card-text>
              <b-row class="form-row">
                <div class="col-12 mb-1">
                  <div class="form-group">
                    <label for="">Depoimento(s) <small>(Aprovados)</small> </label>
                    <v-select
                      multiple
                      ref="selectApproved"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.approveds"
                      placeholder="Digite o título"
                      :options="optionsApproveds"
                      @search="fetchApproveds"
                    >
                      <span slot="no-options" @click="$refs.selectApproved.open = false">
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_store_discount"
                      value="1"
                      class="custom-control-primary"
                    >
                      Não aplicar desconto em loja
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_billet"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desabilitar venda no boleto
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_popup"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar Pop-up de interessado
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.disable_form_contact"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar formulário de contato
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Tags">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <b-form-tags
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      v-model="item.tags"
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 mt-2">Adicione tags separando-as por vírgula.</p>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Status">
            <b-card-text>
              <b-form-group class="mb-0">
                <v-select
                  label="title"
                  v-model="item.status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                  :options="[{ title: 'Ativo', code: 1 }, { title: 'Inativo', code: 0 }]"
                />
              </b-form-group>
            </b-card-text>
          </b-card>
          <ButtonsActionsFooter
            routerBack="products-fast-list"
            variant="success"
            :submited="submited"
            text="Cadastrar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>

    <b-modal
      v-model="modalShow"
      id="createDiscipline"
      centered
      title="Selecionar disciplina"
      hide-footer
      size="lg"
    >
      <b-form @submit.prevent="submitFindDisciplines">
        <b-card-text>
          <div class="form-row justify-content-between">
            <b-col
              cols="12"
              md="4"
              class="align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Tema</label>
                <v-select
                  ref="selectTema"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                >
                  <span slot="no-options" @click="$refs.selectTema.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              class="d-none d-sm-block align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Concurso</label>
                <v-select
                  ref="selectConcurso"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_contest"
                  placeholder="Digite o título"
                  :options="optionsTagConcurso"
                  @search="fetchTagsCategoryConcurso"
                >
                  <span slot="no-options" @click="$refs.selectConcurso.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
            >
              <div class="form-group">
                <label for="">Tag Linha do tempo</label>
                <v-select
                  ref="selectLinhadoTempo"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_timeline"
                  placeholder="Digite o título"
                  :options="optionsTagLinhaDoTempo"
                  @search="fetchTagsCategoryLinhaDoTempo"
                >
                  <span slot="no-options" @click="$refs.selectLinhadoTempo.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            
            <b-col
              cols="6"
              md="8"
            >
              <b-form-group>
                <label for="">Título da disciplina</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Digite o título da disciplina"
                  v-model="filters.title">
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="4"
            >
              <div class="form-group">
                <label for="" style="visibility: hidden;">Buscar</label>
                <button
                  class="btn btn-primary btn-block"
                  type="submit" :disabled="submitedFind">
                    <div v-if="submitedFind">
                      <b-spinner small variant="light" class="left" /> buscando...
                    </div>
                    <div v-else>
                      <feather-icon
                        icon="SearchIcon"
                      />
                      BUSCAR
                    </div>
                </button>
              </div>
            </b-col>
          </div>
          <div class="form-row">
            <div class="col-12">
              <div class="area-lessons d-block mt-2" v-if="!dataItems.length">
                Nenhum registro encontrato até o momento.
              </div>
              <div v-else class="mt-2">
                <b-list-group class="area-lessons mb-1">
                  <b-list-group-item
                    v-for="(row, index) in dataItems"
                    :key="index"
                  >
                    <div class="form-row">
                      <div class="col-md-11" style="line-height: 2">
                        <span class="mr-1">
                          <i class="bi bi-card-list"></i>
                        </span>
                        <span>
                          {{ row.title }}
                        </span>
                      </div>
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="btn btn-block btn-sm btn-primary"
                          @click="AddItemToArray(row)"
                        >
                          <feather-icon icon="PlusCircleIcon" size="16" />
                        </button>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <CustomPaginateTable
                  :rows-table="rowsTable"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                />
              </div>
            </div>
          </div>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ButtonsActionsFooter from '@/views/components/button/ButtonsActionsFooter';
import draggable from "vuedraggable";
import { mapState } from "vuex";
import {
  BFormTags,
  BFormGroup,
  BForm,
  BRow,
  BImg,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BButton,
  BModal,
  BCardFooter,
  BSpinner,
  BListGroup,
  BBadge,
  BListGroupItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import { Money } from "v-money";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import vSelect from "vue-select";
import "swiper/css/swiper.css";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import { required } from "vuelidate/lib/validators";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/l10n/pt.js';

import { stringToSlug } from '@/_helpers/_urlHelper'

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    CustomPaginateTable,
    flatPickr,
    quillEditor,
    BInputGroup,
    BCard,
    FeatherIcon,
    Swiper,
    SwiperSlide,
    BSpinner,
    Money,
    BBadge,
    BFormCheckbox,
    BModal,
    BImg,
    BEmbed,
    BCardText,
    draggable,
    BFormTags,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ButtonsActionsFooter,
  },
  data() {
    return {
      dataItems: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      rowsTable: 0,
      filters: {
        tag_subject: [],
        tag_contest: [],
        tag_timeline: [],
        title: '',
      },
      modalShow: false,
      submited: false,
      submitedFind: false,
      preview: null,
      disciplinesOptions: [],
      user: {},
      filteredOptions: [],
      discipline: '',
      swiperOptions: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      wasUploads: 0,
      snowOption: {
        theme: "snow",
        placeholder: "",
        height: "300",
        modules: {
          clipboard: {
            matchVisual: false
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: ["#00C390", "#031AF5", "#009C73", "#00F7B7", "#4361EE", "#2E49C8", "#1D9EFC", "#1D9EFC", "#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['link', 'video'],
            ['clean'],
          ],
        }
      },
      snowOptionSimple: {
        theme: "snow",
        placeholder: "",
        height: "200",
        modules: {
          clipboard: {
            matchVisual: false
          },
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            
            ['clean'],
          ],
        }
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        title: '',
        label_image: '',
        description: '',
        about: '',
        validity: 365,
        slug: '',
        price: '',
        sale: '',
        title_seo: '',
        description_seo: '',
        status: { title: 'Ativo', code: 1 },
        disciplines: [],
        areas: [],
        books: [],
        tags: [],
        datasheet: '',
        approveds: '',
        subcategory: '',
        disable_store_discount: false,
        disable_billet: false,
        disable_popup: false,
        product_category_uuid: '',
      },
      optionsDatasheets: [],
      optionsAreas: [],
      optionsSalesForce: [],
      optionsSubcategories: [],
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
      optionsApproveds: [],
    };
  },
  watch: {
    'item.slug': {
      handler(){
        this.item.slug = stringToSlug(this.item.slug);
      }
    }
  },
  computed: {
    ...mapState('Discipline', ['searchDisciplines', 'disciplines']),
    ...mapState('Product', ['searchSubcategories']),
    ...mapState('Area', ['searchAreas']),
    ...mapState('Approved', ['searchApproveds']),
  },
  methods: {
    AddItemToArray(event) {
      let checkUniqDuplicate = false;
      this.discipline = null;
      if (!event) return;

      this.item.disciplines.filter((item) => {
        if (item.code === event.uuid) {
          checkUniqDuplicate = true;
        }
      });

      if (!checkUniqDuplicate) {
        this.item.disciplines.push({
          code: event.uuid,
          title: event.title,
        });

        this.notify(
          "Disciplina vinculada com sucesso!",
          "CheckIcon",
          "success",
          null,
          5000
        );
      } else {
        this.notify(
          "Esta disciplina já está vinculada",
          "BellIcon",
          "warning",
          null,
          5000
        );
      }
    },
    submitFindDisciplines() {
      this.submitedFind = true;
      let filtersSearch = {
        tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
        tag_contest: this.filters.tag_contest
          ? this.filters.tag_contest.code
          : "",
        tag_timeline: this.filters.tag_timeline
          ? this.filters.tag_timeline.code
          : "",
        title: this.filters.title ?? "",
      };

      this.$store
        .dispatch("Discipline/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then(() => {
          if (this.disciplines) {
            this.dataItems = this.disciplines.data;
            this.rowsTable = this.disciplines.data.length;
            this.totalRows = this.disciplines.meta.total;
            this.currentPage = this.disciplines.meta.current_page;
          }
        })
        .catch(() => {
          this.notifyDefault("error");
        })
        .finally(() => {
          this.submitedFind = false;
        });
    },
    async fetchApproveds (term) {
      if (term.length > 1) {
        this.$store.dispatch('Approved/search', term).then(() => {
          this.optionsApproveds = this.searchApproveds;
        });
      }
    },
    async fetchSubcategories (term) {
      if (term.length > 2) {
        this.$store.dispatch('Product/searchSubcategory', {uuid: process.env.VUE_APP_PRODUCT_CATEGORY_FAST_UUID, term}).then(() => {
          this.optionsSubcategories = this.searchSubcategories;
        });
      }
    },
    async fetchAreas (term) {
      if (term.length > 2) {
        this.$store.dispatch('Area/search', term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchTagsCategoryTema (term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch('Tag/searchTags', { category_id: 1, term: term })
      }
    },
    async fetchTagsCategoryConcurso (term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch('Tag/searchTags', { category_id: 2, term: term })
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch('Tag/searchTags', { category_id: 3, term: term })
      }
    },
    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch('Datasheet/search', term);
      }
    },
    async fetchSalesForce (term) {
      if (term.length > 2) {
        this.$store.dispatch('SalesForce/search', term).then((response) => {
          this.optionsSalesForce = response;
        });
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    disciplineSelected(event) {
      let checkUniqDuplicate = false;
      this.discipline = null;
      if (!event) return;

      this.item.disciplines.filter((item) => {
        if (item.code === event.code) {
          checkUniqDuplicate = true;
        }
      });

      if (!checkUniqDuplicate) {
        this.item.disciplines.push(event);
        this.disciplinesOptions = [];
        this.notify(
          "Aula vinculada com sucesso!",
          "CheckIcon",
          "success",
          null,
          5000
        );
      } else {
        this.notify(
          "Esta aula já está vinculada.",
          "XIcon",
          "danger",
          null,
          5000
        );
      }
    },
    fetchDisciplinesOptions(term) {
      if (term.length > 2) {
        this.$store.dispatch("Discipline/search", term).then(() => {
          this.disciplinesOptions = this.searchLessons;
        });
      }
    },
    removeAt(idx) {
      this.item.lessons.splice(idx, 1);
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append('image', this.item.image);
      formData.append('title', this.item.title);
      formData.append('title_seo', this.item.title_seo);   
      formData.append('validity', this.item.validity);
      formData.append('price', this.item.price);
      formData.append('sale', this.item.sale);
      formData.append('id_old', this.item.id_old);
      formData.append('description', this.item.description);
      formData.append('about', this.item.about);
      formData.append('description_seo', this.item.description_seo);
      formData.append('label_image', this.item.label_image);
      formData.append('video_url', this.item.video_url ?? '');
      formData.append('datasheet', this.item.datasheet ? this.item.datasheet.code : '');
      formData.append('approveds', this.item.approveds ? JSON.stringify(this.item.approveds) : []);
      formData.append('areas', this.item.areas ? JSON.stringify(this.item.areas) : []);
      formData.append('subcategory', this.item.subcategory ? this.item.subcategory.code : '');
      formData.append('disable_store_discount', this.item.disable_store_discount ? 1: 0);
      formData.append('disable_billet', this.item.disable_billet ? 1: 0);
      formData.append('disable_popup', this.item.disable_popup ? 1: 0);
      formData.append('disable_form_contact', this.item.disable_form_contact ? 1: 0);
      formData.append('tags', this.item.tags ? JSON.stringify(this.item.tags) : '');
      formData.append('status', this.item.status ? this.item.status.code : '');
      formData.append('slug', this.item.slug);
      formData.append('disciplines', this.item.disciplines ? JSON.stringify(this.item.disciplines) : []);
      formData.append('books', this.item.books ? JSON.stringify(this.item.books) : []);
      formData.append('product_category_uuid', process.env.VUE_APP_PRODUCT_CATEGORY_FAST_UUID);
      formData.append('salesforce', this.item.salesforce ? this.item.salesforce.code : '');
      
      if (!this.$v.$error) {        
        this.$store.dispatch('Product/create', formData)
        .then(() => {
          this.$router.push({ name: 'products-fast-list' });
          this.notifyDefault('success');
        })
        .catch((err) => {
          this.submited = false;
          this.notifyDefault('error');
        })
        .finally(() => {
          this.submited = false;
        });
      } else {
        this.submited = false;
        this.notifyDefault("error", 'Verifique os campos obrigatórios');
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      price: {
        required,
      },
      description: {
        required,
      },
      datasheet: {
        required,
      },
      label_image: {
        required,
      },
      areas: {
        required,
      },
      subcategory: {
        required,
      },
      validity: {
        required,
      },
      tags: {
        required,
      },
      status: {
        required,
      },
      salesforce: {
        required,
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    this.$root.$on('paginate:update', (res) => {
      this.currentPage = res._currentPage
      this.totalRows = res._totalRows
      this.rowsTable = res._rowsTable
      this.perPage = res._perPage
      this.submitFindDisciplines();
    });
  },
  beforeDestroy() {
    this.$root.$off('paginate:update');
  },
};
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.custom-checkbox-footer label {
  font-size: 0.9rem!important;
}
.container-uploads svg{
  width: 70px;
}
.b-form-tags.form-control {
  padding: 1rem 1rem!important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
</style>
